<template>
  <div id="login">
    <header>
      <div class="imgDiv">
        <img src="../../assets/images/logoH.png" alt />
      </div>
      <div class="title">
        <p>双重预防机制安全管理平台</p>
        <p>SECURITY SMART CLOUD PLATFORM</p>
      </div>
    </header>
    <div class="container">
      <div class="loginLeft">
        <img src="../../assets/images/bg02.png" alt />
      </div>
      <div class="loginRight">
        <div class="title">密码登录</div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item prop="name" class="loginUserName">
            <el-input placeholder="请输入用户名" v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item prop="region" class="loginPsd">
            <el-input type="password" placeholder="请输入用密码" v-model="ruleForm.region"></el-input>
          </el-form-item>
        </el-form>
        <el-button @click="loginSubmit('ruleForm')" class="loginBtn">登录</el-button>
      </div>
    </div>
    <div class="fotters">
      <p class="fotter" @click="goHomepage">打造服务行业领先品牌</p>
      <p class="fotter" @click="goHomepage">Copyright 2020 ©河南邦泰合力管理咨询有限公司 版权所有 豫ICP备11032258号</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ruleForm: {
        name: '',
        region: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },--
        ],
        region: [
          { required: true, message: '请输入登录密码', trigger: 'change' },
        ],
      },
    }
  },
  methods: {
    goHomepage() {
      window.location.href = 'http://www.bangtaiheli.com/'
    },
    loginSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (!valid) return this.$message.error('登录信息有误')
        let param = new URLSearchParams()
        param.append('username', this.ruleForm.name)
        param.append('password', this.ruleForm.region)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/login/login',
          data: param,
        })
        console.log(res)
        // 如果向服务器提交失败
        if (res.code != 200) return this.$message.error(res.datas.msg)
        // 如果成功
        console.log(res)
        window.localStorage.setItem('btlh_nickname', res.datas.nickname)
        window.localStorage.setItem('btlh_companyid', res.datas.company_id)
        window.localStorage.setItem('btlh_id', res.datas.id)
        window.localStorage.setItem('btlh_token', res.datas.access_token)
        window.localStorage.setItem('btlh_loacation', res.datas.loacation_name)
        window.localStorage.setItem('btlh_is_root', res.datas.is_root)
        document.title = res.datas.nickname + '-邦泰云安全'
        this.$message.success('登录成功')
        // this.$router.push('/home')
        this.$router.push('/welcome')
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>
<style lang="less" scoped>
#login {
  width: 100%;
  height: 100%;
  position: relative;
  header {
    padding: 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .imgDiv {
      width: 70px;
      height: 70px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      p {
        -moz-user-select: none; /*火狐*/
        -webkit-user-select: none; /*webkit浏览器*/
        -ms-user-select: none; /*IE10*/
        -khtml-user-select: none; /*早期浏览器*/
        user-select: none;
        margin: 0;
      }
      p:nth-child(1) {
        font-size: 30px;
      }
      p:nth-child(2) {
        font-size: 12px;
      }
    }
  }
  .container {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    height: 440px;
    border: 1px solid #eee;
    display: flex;
    .loginLeft {
      width: 500px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .loginRight {
      width: 400px;
      .title {
        font-size: 26px;
        text-align: center;
        margin-top: 70px;
        margin-bottom: 30px;
      }
      .loginUserName {
        background: url(../../assets/images/userLogo.png) no-repeat;
        background-size: 25px;
        background-position: 60px 8px;
        /deep/.el-input__inner {
          border-radius: 0;
        }
        /deep/ .el-form-item__content {
          margin-left: 100px !important;
          margin-right: 70px !important;
        }
      }
      .loginPsd {
        background: url(../../assets/images/psdLogo.png) no-repeat;
        background-size: 25px;
        background-position: 60px 8px;
        /deep/.el-input__inner {
          border-radius: 0;
        }
        /deep/ .el-form-item__content {
          margin-left: 100px !important;
          margin-right: 70px !important;
        }
      }
      .loginBtn {
        margin-left: 105px;
        width: 220px;
        height: 46px;
        border-radius: 23px;
        color: #fff;
        font-size: 16px;
        background-color: #447ed9;
      }
    }
  }
  .fotters {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    .fotter {
      cursor: pointer;
      text-align: center;
    }
  }
}
</style>
