<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <!-- <span>大数据搜索</span> -->
      <span>企业列表</span>
    </el-row>
    <el-row class="selectRow">
      <div class="selectCol">
        <span>企业分类</span>
        <el-select clearable v-model="valueClass" placeholder="请选择">
          <el-option v-for="item in classList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <div class="selectCol">
        <span>所属平台</span>
        <el-select clearable v-model="platId" placeholder="请选择">
          <el-option v-for="item in platList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="selectCol">
        <span>企业名称</span>
        <el-input v-model="companyName" placeholder="请输入内容"></el-input>
      </div>
      <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
    </el-row>
    <el-row class="tableRow">
      <el-table border :data="tableData" stripe style="width: 100%">
        <el-table-column align="center" label="企业编号" width="100">
          <template slot-scope="scope">
            <span>{{scope.row.ordernum}}</span>
            <!-- <span v-if="scope.row.is_mine=='1'">{{scope.row.id}}</span> -->
            <!-- <span v-if="scope.row.is_mine=='0'">{{scope.row.ordernum}}</span> -->
          </template>
        </el-table-column>
        <el-table-column align="center" prop="nickname" label="企业名称">
          <template slot-scope="scope">
            <span :title="scope.row.nickname" class="overflowDoit">{{scope.row.nickname}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="所属系统">
          <template slot-scope="scope">
            <span :title="scope.row.name" class="overflowDoit">{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="charge_name" label="主要负责人" width="100"></el-table-column>
        <el-table-column align="center" prop="charge_mobile" label="主要负责人手机号" width="150">
          <template slot-scope="scope">
            <span :title="scope.row.charge_mobile" class="overflowDoit">{{scope.row.charge_mobile}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="type" label="企业分类" width="140">
          <template slot-scope="scope">
            <span :title="scope.row.type" class="overflowDoit">{{scope.row.type}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="inspectionone_precent" label="巡检率" width="80">
          <template slot-scope="scope">
            <span
              :title="scope.row.inspectionone_precent"
              class="overflowDoit"
            >{{scope.row.inspectionone_precent}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click="checkOut(scope.row)" class="tableBtn">查看详情</el-button>
            <el-button @click="handleEdit(scope.row)" class="tableBtn">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15,20, 30, 40]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>
    <!-- 查看详情dialog开始 -->
    <el-dialog
      :title="checkOutName"
      class="seletDia"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      @close="handleClosed"
    >
      <el-button @click="riskClick">风险管控清单</el-button>
      <el-button @click="infoClick">企业信息</el-button>
      <el-button @click="equipmentClick">设备设施清单</el-button>
      <!-- <el-button @click="equipmentRiskClick">设备设施风险评价清单</el-button> -->
      <el-button @click="workClick">作业活动清单</el-button>
      <!-- <el-button @click="workRiskClick">作业活动风险评价清单</el-button> -->
      <el-button @click="dangerClick">隐患排查清单</el-button>
      <el-button @click="colorClick">企业四色图</el-button>
      <el-button @click="historyClick">巡检记录</el-button>
      <el-button v-if="isMine" @click="goDatacheck">企业大屏</el-button>
      <el-button v-if="isMine" @click="goTestcheck">考试记录</el-button>
    </el-dialog>
    <!-- 查看详情dialog结束 -->
    <!-- 风险管控dia开始 -->
    <!-- :title="`风险管控清单(${userName})`" -->
    <el-dialog
      :title="`风险管控清单`"
      class="riskDia"
      :visible.sync="riskDialogVisible"
      width="80%"
      :before-close="riskHandleClose"
    >
      <el-row class="tableRow">
        <el-table border :data="tableData1" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
          <el-table-column align="center" prop="company_name" width="200" label="企业名称">
            <template slot-scope="scope">
              <span :title="scope.row.company_name" class="overflowDoit">{{scope.row.company_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_name" label="风险点名称">
            <template slot-scope="scope">
              <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_accident" label="易发生事故类型">
            <template slot-scope="scope">
              <span
                :title="scope.row.danger_accident"
                class="overflowDoit"
              >{{scope.row.danger_accident}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="风险分级">
            <template slot-scope="scope">
              <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
              <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
              <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
              <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="position_id" label="部门" width="100">
            <template slot-scope="scope">
              <span :title="scope.row.position_id" class="overflowDoit">{{scope.row.position_id}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="charge_userid" label="负责人">
            <template slot-scope="scope">
              <span
                :title="scope.row.charge_userid"
                class="overflowDoit"
              >{{scope.row.charge_userid}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="140">
            <template slot-scope="scope">
              <el-button @click="checkOutRisk(scope.row)" class="tableBtnRisk">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length>0"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="currentPage1"
          :page-sizes="[3, 5, 10, 15,20]"
          :page-size="pageSize1"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount1"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 风险管控dia结束 -->
    <!-- 风险管控详情dia开始 -->
    <!-- :title="`风险管控清单详情(${userName}-${riskName})`" -->
    <el-dialog
      :title="`风险管控清单详情(${riskName})`"
      class="riskDia"
      :visible.sync="riskDialogDetail"
      width="45%"
      :before-close="riskDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData1" label-width="120px">
          <el-form-item label="风险点名称">
            <el-input style="width:80%" disabled v-model="formData1.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="风险分级">
            <el-input style="width:80%" disabled v-model="formData1.danger_level_show"></el-input>
          </el-form-item>
          <el-form-item label="所在区域">
            <el-input style="width:80%" disabled v-model="formData1.danger_position"></el-input>
          </el-form-item>
          <el-form-item label="易发生事故类型" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              disabled
              v-model="formData1.danger_accident"
            ></el-input>
          </el-form-item>
          <el-form-item label="主要危险有害性" prop="desc">
            <el-input class="modifyInner" type="textarea" disabled v-model="formData1.reason"></el-input>
          </el-form-item>
          <el-form-item label="措施" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              disabled
              v-model="formData1.danger_measures"
            ></el-input>
          </el-form-item>
          <el-form-item label="应急处置对策" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              disabled
              v-model="formData1.change_measures"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 风险管控详情dia结束 -->
    <!-- 设备设施清单dia开始 -->
    <!-- :title="`设备设施清单(${userName})`" -->
    <el-dialog
      :title="`设备设施清单`"
      class="riskDia"
      :visible.sync="equipmentDialogVisible"
      width="80%"
      :before-close="equipmentHandleClose"
    >
      <el-row class="tableRow">
        <el-table border :data="tableData2" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
          <el-table-column align="center" prop="company_name" label="企业名称">
            <template slot-scope="scope">
              <span :title="scope.row.company_name" class="overflowDoit">{{scope.row.company_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_name" label="设备设施名称">
            <template slot-scope="scope">
              <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="count" label="数量" width="100"></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                style="margin:0 auto!important;"
                @click="checkOutEquipment(scope.row)"
                class="tableBtnRisk"
              >查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length>0"
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="currentPage2"
          :page-sizes="[3, 5, 10,15, 20]"
          :page-size="pageSize2"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount2"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 设备设施清单dia结束 -->
    <!-- 设备设施清单查看详情dia开始 -->
    <!-- :title="`设备设施清单详情(${userName}-${equipmentName})`" -->
    <el-dialog
      :title="`设备设施清单详情(${equipmentName})`"
      class="riskDia"
      :visible.sync="equipmentDialogDetail"
      width="40%"
      :before-close="equipmentDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData2" label-width="180px">
          <el-form-item label="风险点名称">
            <el-input style="width:60%" disabled v-model="formData2.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="型号规格">
            <el-input style="width:60%" disabled v-model="formData2.model"></el-input>
          </el-form-item>
          <el-form-item label="主要物料">
            <el-input style="width:60%" disabled v-model="formData2.main_materials"></el-input>
          </el-form-item>
          <el-form-item label="相态">
            <el-input style="width:60%" disabled v-model="formData2.phase_state"></el-input>
          </el-form-item>
          <el-form-item label="温度/℃">
            <el-input style="width:60%" disabled v-model="formData2.temperature"></el-input>
          </el-form-item>
          <el-form-item label="压力/Mpa">
            <el-input style="width:60%" disabled v-model="formData2.pressure"></el-input>
          </el-form-item>
          <el-form-item label="体积/立方米">
            <el-input style="width:60%" disabled v-model="formData2.volume"></el-input>
          </el-form-item>
          <el-form-item label="浓度/V%或W%">
            <el-input style="width:60%" disabled v-model="formData2.concentration"></el-input>
          </el-form-item>
          <el-form-item label="危险物质存储量或滞留量/kg">
            <el-input style="width:60%" disabled v-model="formData2.danger_capacity"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              style="width:75%"
              disabled
              v-model="formData2.danger_remarks"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 设备设施清单查看详情dia结束 -->

    <!-- 设备设施风险评价清单dia开始 -->

    <!-- :title="`设备设施风险评价清单(${userName})`" -->
    <el-dialog
      :title="`设备设施风险评价清单`"
      class="riskDia"
      :visible.sync="equipmentRisk"
      width="80%"
      :before-close="equipmentRiskClose"
    >
      <el-row class="tableRow">
        <el-table border :data="tableData3" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
          <el-table-column align="center" prop="company_name" label="企业名称">
            <template slot-scope="scope">
              <span :title="scope.row.company_name" class="overflowDoit">{{scope.row.company_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_name" label="风险点名称">
            <template slot-scope="scope">
              <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_remarks" label="检查内容">
            <template slot-scope="scope">
              <span
                :title="scope.row.danger_remarks"
                class="overflowDoit"
              >{{scope.row.danger_remarks}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="风险分级">
            <template slot-scope="scope">
              <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
              <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
              <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
              <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="position_id" label="部门" width="100">
            <template slot-scope="scope">
              <span :title="scope.row.position_id" class="overflowDoit">{{scope.row.position_id}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="charge_userid" label="负责人">
            <template slot-scope="scope">
              <span
                :title="scope.row.charge_userid"
                class="overflowDoit"
              >{{scope.row.charge_userid}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                style="margin:0 auto!important;"
                @click="checkOutEquipmentRisk(scope.row)"
                class="tableBtnRisk"
              >查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length>0"
          @size-change="handleSizeChange3"
          @current-change="handleCurrentChange3"
          :current-page="currentPage3"
          :page-sizes="[3, 5, 10, 15,20]"
          :page-size="pageSize3"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount3"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 设备设施风险评价清单dia结束 -->
    <!-- 设备设施风险评价清单查看详情dia开始 -->
    <!-- :title="`风险设施风险评价详情(${userName}-${equipmentName2})`" -->
    <el-dialog
      :title="`风险设施风险评价详情(${equipmentName2})`"
      class="riskDia"
      :visible.sync="equipmentDetail"
      width="40%"
      :before-close="equipmentRiskDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData" label-width="170px">
          <el-form-item label="风险点名称">
            <el-input style="width:70%" disabled v-model="formData3.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="风险分级">
            <el-input style="width:70%" disabled v-model="formData3.danger_level_show"></el-input>
          </el-form-item>
          <el-form-item label="L">
            <el-input style="width:70%" disabled v-model="formData3.activity_ev_l"></el-input>
          </el-form-item>
          <el-form-item label="S">
            <el-input style="width:70%" disabled v-model="formData3.facility_eva_s"></el-input>
          </el-form-item>
          <el-form-item label="R">
            <el-input style="width:70%" disabled v-model="formData3.facility_eva_r"></el-input>
          </el-form-item>
          <el-form-item label="工作内容" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              style="width:88%"
              disabled
              v-model="formData3.danger_remarks"
            ></el-input>
          </el-form-item>
          <el-form-item label="检验要求" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData3.check_standard"
            ></el-input>
          </el-form-item>
          <el-form-item label="潜在事故类型" prop="desc">
            <el-input
              style="width:88%"
              class="modifyInner"
              type="textarea"
              disabled
              v-model="formData3.danger_accident"
            ></el-input>
          </el-form-item>
          <el-form-item label="已有控制措施" prop="desc">
            <el-input
              style="width:88%"
              class="modifyInner"
              type="textarea"
              disabled
              v-model="formData3.danger_measures"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 设备设施风险评价清单查看详情dia结束 -->

    <!-- 作业活动清单dia开始 -->
    <!-- :title="`作业活动清单(${userName})`" -->
    <el-dialog
      :title="`作业活动清单`"
      class="riskDia"
      :visible.sync="workVisible"
      width="80%"
      :before-close="workClose"
    >
      <el-row class="tableRow">
        <el-table border :data="tableData4" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
          <el-table-column align="center" prop="company_name" label="企业名称">
            <template slot-scope="scope">
              <span :title="scope.row.company_name" class="overflowDoit">{{scope.row.company_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_accident" label="作业活动">
            <template slot-scope="scope">
              <span
                :title="scope.row.danger_accident"
                class="overflowDoit"
              >{{scope.row.danger_accident}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_name" label="作业区域">
            <template slot-scope="scope">
              <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="type" label="作业类别">
            <template slot-scope="scope">
              <span :title="scope.row.type" class="overflowDoit">{{scope.row.type}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_remarks" label="备注">
            <template slot-scope="scope">
              <span
                :title="scope.row.danger_remarks"
                class="overflowDoit"
              >{{scope.row.danger_remarks}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                style="margin:0 auto!important;"
                @click="checkOutWork(scope.row)"
                class="tableBtnRisk"
              >查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length>0"
          @size-change="handleSizeChange4"
          @current-change="handleCurrentChange4"
          :current-page="currentPage4"
          :page-sizes="[3, 5, 10,15, 20]"
          :page-size="pageSize4"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount4"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 作业活动清单dia结束 -->
    <!-- 作业活动清单查看dia开始 -->
    <!-- :title="`作业活动清单详情(${userName}-${workName})`" -->

    <el-dialog
      :title="`作业活动清单详情(${workName})`"
      class="riskDia"
      :visible.sync="workDetail"
      width="40%"
      :before-close="workDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData4" label-width="160px">
          <el-form-item label="作业活动">
            <el-input style="width:70%" disabled v-model="formData4.danger_accident"></el-input>
          </el-form-item>
          <el-form-item label="作业区域">
            <el-input style="width:70%" disabled v-model="formData4.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="作业类别">
            <el-input style="width:70%" disabled v-model="formData4.type"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              disabled
              style="width:88%"
              v-model="formData4.danger_remarks"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 作业活动清单查看dia开始 -->

    <!-- 作业活动风险评价dia开始 -->
    <!-- :title="`作业活动风险评价清单(${userName})`" -->
    <el-dialog
      :title="`作业活动风险评价清单`"
      class="riskDia"
      :visible.sync="workRisk"
      width="80%"
      :before-close="workRiskClose"
    >
      <el-row class="tableRow">
        <el-table border :data="tableData5" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
          <el-table-column align="center" prop="company_name" width="200" label="企业名称">
            <template slot-scope="scope">
              <span :title="scope.row.company_name" class="overflowDoit">{{scope.row.company_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_name" label="风险点名称">
            <template slot-scope="scope">
              <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_accident" width="300" label="潜在事故类型">
            <template slot-scope="scope">
              <span
                :title="scope.row.danger_accident"
                class="overflowDoit"
              >{{scope.row.danger_accident}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_level" label="风险分级">
            <template slot-scope="scope">
              <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
              <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
              <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
              <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="position_id" label="部门" width="100">
            <template slot-scope="scope">
              <span :title="scope.row.position_id" class="overflowDoit">{{scope.row.position_id}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="charge_userid" label="负责人">
            <template slot-scope="scope">
              <span
                :title="scope.row.charge_userid"
                class="overflowDoit"
              >{{scope.row.charge_userid}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                style="margin:0 auto!important;"
                @click="checkOutWorkRisk(scope.row)"
                class="tableBtnRisk"
              >查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length>0"
          @size-change="handleSizeChange5"
          @current-change="handleCurrentChange5"
          :current-page="currentPage5"
          :page-sizes="[3, 5, 10,15, 20]"
          :page-size="pageSize5"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount5"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 作业活动风险评价dia开始 -->
    <!-- 作业活动风险评价详情查看dia开始 -->
    <!-- :title="`作业活动风险评价清单详情(${userName}-${workRiskName})`" -->
    <el-dialog
      :title="`作业活动风险评价清单详情(${workRiskName})`"
      class="riskDia"
      :visible.sync="workRiskDetail"
      width="40%"
      :before-close="workRiskDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData5" label-width="160px">
          <el-form-item label="风险点名称">
            <el-input style="width:70%" disabled v-model="formData5.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="风险分级">
            <el-input style="width:70%" disabled v-model="formData5.danger_level_show"></el-input>
          </el-form-item>
          <el-form-item label="L">
            <el-input style="width:70%" disabled v-model="formData5.activity_ev_l"></el-input>
          </el-form-item>
          <el-form-item label="E">
            <el-input style="width:70%" disabled v-model="formData5.activity_ev_e"></el-input>
          </el-form-item>
          <el-form-item label="C">
            <el-input style="width:70%" disabled v-model="formData5.activity_ev_c"></el-input>
          </el-form-item>
          <el-form-item label="D">
            <el-input style="width:70%" disabled v-model="formData5.activity_ev_d"></el-input>
          </el-form-item>
          <el-form-item label="工作内容" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData5.danger_remarks"
            ></el-input>
          </el-form-item>
          <el-form-item label="矫正措施" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData5.change_measures"
            ></el-input>
          </el-form-item>
          <el-form-item label="潜在事故类型" prop="desc">
            <el-input
              style="width:88%"
              class="modifyInner"
              type="textarea"
              disabled
              v-model="formData5.danger_accident"
            ></el-input>
          </el-form-item>
          <el-form-item label="已有控制措施" prop="desc">
            <el-input
              style="width:88%"
              class="modifyInner"
              type="textarea"
              disabled
              v-model="formData5.danger_measures"
            ></el-input>
          </el-form-item>
          <el-form-item label="事故原因分析" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData5.reason"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 作业活动风险评价详情查看dia开始 -->

    <!-- 隐患排查清单dia开始 -->
    <!-- :title="`隐患排查清单(${userName})`" -->

    <el-dialog
      :title="`隐患排查清单`"
      class="riskDia"
      :visible.sync="dangerCheck"
      width="80%"
      :before-close="dangerCheckClose"
    >
      <el-row class="tableRow">
        <el-table border :data="tableData6" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
          <el-table-column align="center" prop="company_name" label="企业名称">
            <template slot-scope="scope">
              <span :title="scope.row.company_name" class="overflowDoit">{{scope.row.company_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_name" label="风险点名称">
            <template slot-scope="scope">
              <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_accident" label="潜在事故类型">
            <template slot-scope="scope">
              <span
                :title="scope.row.danger_accident"
                class="overflowDoit"
              >{{scope.row.danger_accident}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="reason" label="事故原因分析">
            <template slot-scope="scope">
              <span :title="scope.row.reason" class="overflowDoit">{{scope.row.reason}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_measures" label="已有控制措施" width="300">
            <template slot-scope="scope">
              <span
                :title="scope.row.danger_measures"
                class="overflowDoit"
              >{{scope.row.danger_measures}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_level" label="风险分级">
            <template slot-scope="scope">
              <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
              <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
              <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
              <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="150">
            <template slot-scope="scope">
              <el-button @click="handleDangerCheck(scope.row)" class="tableBtnRisk">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length>0"
          @size-change="handleSizeChange6"
          @current-change="handleCurrentChange6"
          :current-page="currentPage6"
          :page-sizes="[3, 5, 10,15, 20]"
          :page-size="pageSize6"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount6"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 隐患排查清单dia开始 -->
    <!-- 作业活动风险评价详情查看dia开始 -->
    <!-- :title="`作业活动风险评价详情(${userName}-${dangerName1})`" -->
    <el-dialog
      :title="`作业活动风险评价详情(${dangerName1})`"
      class="riskDia"
      :visible.sync="dangerCheckDetail"
      width="40%"
      :before-close="dangerCheckDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData6" label-width="160px">
          <el-form-item label="风险点名称">
            <el-input style="width:70%" disabled v-model="formData6.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="风险分级">
            <el-input style="width:70%" disabled v-model="formData6.danger_level_show"></el-input>
          </el-form-item>
          <el-form-item label="工作内容" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              style="width:88%"
              disabled
              v-model="formData6.danger_remarks"
            ></el-input>
          </el-form-item>
          <el-form-item label="矫正措施" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData6.change_measures"
            ></el-input>
          </el-form-item>
          <el-form-item label="潜在事故类型" prop="desc">
            <el-input
              style="width:88%"
              class="modifyInner"
              type="textarea"
              disabled
              v-model="formData6.danger_accident"
            ></el-input>
          </el-form-item>
          <el-form-item label="已有控制措施" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData6.danger_measures"
            ></el-input>
          </el-form-item>
          <el-form-item label="事故原因分析" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData6.reason"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 作业活动风险评价详情查看dia开始 -->

    <!-- 巡检记录dia开始 -->

    <!-- :title="`巡检记录清单(${userName})`" -->
    <el-dialog
      :title="`巡检记录清单`"
      class="riskDia"
      :visible.sync="historyV"
      width="80%"
      :before-close="historyClose"
    >
      <el-row class="tableRow">
        <el-table border :data="tableData7" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
          <el-table-column align="center" prop="company_name" label="企业名称">
            <template slot-scope="scope">
              <span :title="scope.row.company_name" class="overflowDoit">{{scope.row.company_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="danger_name" label="风险点名称">
            <template slot-scope="scope">
              <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="safety_inspection_name" label="班次名称">
            <template slot-scope="scope">
              <span
                :title="scope.row.safety_inspection_name"
                class="overflowDoit"
              >{{scope.row.safety_inspection_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="user_name" label="巡检人">
            <template slot-scope="scope">
              <span :title="scope.row.user_name" class="overflowDoit">{{scope.row.user_name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="巡检状态">
            <template slot-scope="scope">
              <span style="color:#4e89db" v-if="scope.row.status==1">正常</span>
              <span style="color:#e84649" v-if="scope.row.status==2">异常</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="add_time" label="巡检时间">
            <template slot-scope="scope">
              <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="inspection_status_show" label="整改状态">
            <template slot-scope="scope">
              <span
                :title="scope.row.inspection_status_show"
                class="overflowDoit"
              >{{scope.row.inspection_status_show}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="240">
            <template slot-scope="scope">
              <div style="display:flex">
                <el-button
                  @click="checkOutXun(scope.row)"
                  style="height:30px;padding:0;margin:0"
                  class="tableBtn modifyBtn"
                >巡检详情</el-button>
                <el-button
                  @click="changeHistory(scope.row.id)"
                  style="height:30px;padding:0;margin:0"
                  class="tableBtn modifyBtn"
                >整改记录</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length>0"
          @size-change="handleSizeChange7"
          @current-change="handleCurrentChange7"
          :current-page="currentPage7"
          :page-sizes="[3, 5, 10,15, 20]"
          :page-size="pageSize7"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount7"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 巡检记录dia结束 -->

    <!-- 企业四色图开始dia开始 -->
    <!-- :title="`企业四色图(${userName})`" -->

    <el-dialog
      :title="`企业四色图`"
      class="riskDia"
      :visible.sync="colorPic"
      width="40%"
      :before-close="colorPicClose"
    >
      <el-row class="tableRow">
        <img style="width:100%" :src="fourColorPic" alt />
      </el-row>
    </el-dialog>
    <!-- 企业四色图开始dia结束 -->

    <!-- 企业信息 -->
    <!-- :title="`企业信息(${userName})`" -->

    <el-dialog
      :title="`企业信息`"
      class="riskDia modifyHeight"
      :visible.sync="infoVisibale"
      width="40%"
      :before-close="infoVisibaleClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="companyData" label-width="173px">
          <el-form-item label="企业名称">
            <el-input style="width:60%" disabled v-model="companyData.nickname"></el-input>
          </el-form-item>
          <el-form-item label="企业简称">
            <el-input style="width:60%" disabled v-model="companyData.short_name"></el-input>
          </el-form-item>
          <el-form-item label="企业分类">
            <el-input style="width:60%" disabled v-model="companyData.type"></el-input>
          </el-form-item>
          <el-form-item label="主要负责人">
            <el-input style="width:60%" disabled v-model="companyData.charge_name"></el-input>
          </el-form-item>
          <el-form-item label="主要负责人手机号">
            <el-input style="width:60%" disabled v-model="companyData.charge_mobile"></el-input>
          </el-form-item>
          <el-form-item label="安全负责人">
            <el-input style="width:60%" disabled v-model="companyData.safe_name"></el-input>
          </el-form-item>
          <el-form-item label="安全负责人手机号">
            <el-input style="width:60%" disabled v-model="companyData.safe_mobile"></el-input>
          </el-form-item>
          <el-form-item label="企业地址">
            <el-input style="width:60%" disabled v-model="companyData.address"></el-input>
          </el-form-item>

          <el-form-item label="主管部门">
            <el-input style="width:60%" disabled v-model="companyData.competent_department"></el-input>
          </el-form-item>

          <el-form-item label="职工总数">
            <el-input style="width:60%" disabled v-model="companyData.member_num"></el-input>
          </el-form-item>
          <el-form-item label="成立时间">
            <el-input style="width:60%" disabled v-model="companyData.setup_time"></el-input>
          </el-form-item>
          <el-form-item label="所在区县">
            <el-input style="width:60%" disabled v-model="companyData.area"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码">
            <el-input style="width:60%" disabled v-model="companyData.credit_code"></el-input>
          </el-form-item>
          <el-form-item label="固定资产">
            <el-input style="width:60%" disabled v-model="companyData.fixed_assets"></el-input>
          </el-form-item>
          <el-form-item label="组建面积">
            <el-input style="width:60%" disabled v-model="companyData.floorage"></el-input>
          </el-form-item>
          <el-form-item label="所辖单位">
            <el-input style="width:60%" disabled v-model="companyData.subsidiary"></el-input>
          </el-form-item>
          <el-form-item label="安全管理机构名称">
            <el-input style="width:60%" disabled v-model="companyData.organization_name"></el-input>
          </el-form-item>
          <el-form-item label="安全管理机构电话">
            <el-input style="width:60%" disabled v-model="companyData.organization_mobile"></el-input>
          </el-form-item>
          <el-form-item label="值班电话">
            <el-input style="width:60%" disabled v-model="companyData.duty_mobile"></el-input>
          </el-form-item>
          <el-form-item label="专职安全管理人员数">
            <el-input style="width:60%" disabled v-model="companyData.professionals_num"></el-input>
          </el-form-item>
          <el-form-item label="兼职安全管理人员数">
            <el-input style="width:60%" disabled v-model="companyData.parttime_num"></el-input>
          </el-form-item>
          <el-form-item label="是否有危化品重大危险源">
            <el-input style="width:60%" disabled v-model="companyData.is_safe_show"></el-input>
          </el-form-item>
          <el-form-item label="是否进行风险辨识">
            <el-input style="width:60%" disabled v-model="companyData.is_risk_show"></el-input>
          </el-form-item>
          <el-form-item label="危化品重大危险源是否备案">
            <el-input style="width:60%" disabled v-model="companyData.is_record_show"></el-input>
          </el-form-item>

          <el-form-item label="企业简介" prop="desc">
            <el-input
              class="modifyInner"
              style="width:75%"
              type="textarea"
              disabled
              v-model="companyData.content"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 企业信息 -->

    <!-- 企业信息编辑 -->
    <!-- :title="`企业信息(${userName})`" -->
    <el-dialog
      :title="`企业信息编辑`"
      class="riskDia modifyHeight"
      :visible.sync="editDia"
      width="40%"
      :before-close="editDiaClose"
      @close="editDiaClosed"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="editData" label-width="180px">
          <el-form-item label="企业名称">
            <el-input style="width:60%" v-model="editData.nickname"></el-input>
          </el-form-item>
          <el-form-item label="企业简称">
            <el-input style="width:60%" v-model="editData.short_name"></el-input>
          </el-form-item>
          <el-form-item label="企业分类">
            <!-- <el-input style="width:60%" v-model="editData.type"></el-input> -->
            <el-select clearable style="width:60%" v-model="editData.type" placeholder="请选择">
              <el-option v-for="item in editClassList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="主要负责人">
            <el-input style="width:60%" v-model="editData.charge_name"></el-input>
          </el-form-item>
          <el-form-item label="主要负责人手机号">
            <el-input style="width:60%" v-model="editData.charge_mobile"></el-input>
          </el-form-item>

          <el-form-item label="安全负责人">
            <el-input style="width:60%" v-model="editData.safe_name"></el-input>
          </el-form-item>
          <el-form-item label="安全负责人手机号">
            <el-input style="width:60%" v-model="editData.safe_mobile"></el-input>
          </el-form-item>
          <el-form-item label="企业地址">
            <el-input style="width:60%" v-model="editData.address"></el-input>
          </el-form-item>

          <el-form-item label="主管部门">
            <el-input style="width:60%" v-model="editData.competent_department"></el-input>
          </el-form-item>

          <el-form-item label="职工总数">
            <el-input style="width:60%" v-model="editData.member_num"></el-input>
          </el-form-item>
          <el-form-item label="成立时间">
            <el-input style="width:60%" v-model="editData.setup_time"></el-input>
          </el-form-item>
          <el-form-item label="所在区县">
            <el-input style="width:60%" v-model="editData.area"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码">
            <el-input style="width:60%" v-model="editData.credit_code"></el-input>
          </el-form-item>
          <el-form-item label="固定资产">
            <el-input style="width:60%" v-model="editData.fixed_assets"></el-input>
          </el-form-item>
          <el-form-item label="组建面积">
            <el-input style="width:60%" v-model="editData.floorage"></el-input>
          </el-form-item>
          <el-form-item label="所辖单位">
            <el-input style="width:60%" v-model="editData.subsidiary"></el-input>
          </el-form-item>
          <el-form-item label="安全管理机构名称">
            <el-input style="width:60%" v-model="editData.organization_name"></el-input>
          </el-form-item>
          <el-form-item label="安全管理机构电话">
            <el-input style="width:60%" v-model="editData.organization_mobile"></el-input>
          </el-form-item>
          <el-form-item label="值班电话">
            <el-input style="width:60%" v-model="editData.duty_mobile"></el-input>
          </el-form-item>
          <el-form-item label="专职安全管理人员数">
            <el-input style="width:60%" v-model="editData.professionals_num"></el-input>
          </el-form-item>
          <el-form-item label="兼职安全管理人员数">
            <el-input style="width:60%" v-model="editData.parttime_num"></el-input>
          </el-form-item>
          <el-form-item label="是否有危化品重大危险源">
            <el-select style="width:60%" clearable v-model="editData.is_safe" placeholder="请选择">
              <el-option
                v-for="item in isTrue"
                :key="item.label"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否进行风险辨识">
            <el-select clearable style="width:60%" v-model="editData.is_risk" placeholder="请选择">
              <el-option
                v-for="item in isTrue"
                :key="item.label"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="危化品重大危险源是否备案">
            <el-select clearable style="width:60%" v-model="editData.is_record" placeholder="请选择">
              <el-option
                v-for="item in isTrue1"
                :key="item.label"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="企业简介" prop="desc">
            <el-input
              class="modifyInner"
              style="width:75%"
              type="textarea"
              v-model="editData.content"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDia = false">取 消</el-button>
        <el-button type="primary" @click="editFormSubmit">保存</el-button>
      </span>
    </el-dialog>
    <!-- 企业信息编辑 -->

    <!-- 巡检详情点击dia -->
    <!-- 巡检详情dia开始 -->
    <!-- :title="`巡检记录巡检详情(${userName})`" -->
    <el-dialog
      :title="`巡检记录巡检详情`"
      class="riskDia"
      :visible.sync="dialogDetailXun"
      width="50%"
      :before-close="dialogDetailXunClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formDataXun" label-width="120px">
          <el-form-item label="风险点名称">
            <el-input style="width:70%" disabled v-model="formDataXun.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="班次名称">
            <el-input style="width:70%" disabled v-model="formDataXun.safety_inspection_name"></el-input>
          </el-form-item>
          <el-form-item label="巡检人">
            <el-input style="width:70%" disabled v-model="formDataXun.user_name"></el-input>
          </el-form-item>
          <el-form-item label="巡检状态">
            <el-input
              style="width:70%"
              :class="formDataXun.status_show == '正常' ?'isBlue':'isRed'"
              disabled
              v-model="formDataXun.status_show"
            ></el-input>
          </el-form-item>
          <el-form-item label="巡检备注" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formDataXun.remark"
            ></el-input>
          </el-form-item>
          <el-form-item label="巡检图片或视频" prop="desc" style="padding-top:10px;">
            <img
              v-if="detailImgList.length>0"
              v-for="item in detailImgList"
              @click="showPic(item)"
              style="width: 220px;height: 130px; margin-right:10px;"
              :src="item"
              alt
            />
            <img
              v-if="detailImgList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noPic.png"
              alt
            />
            <!-- controls="controls" -->
            <video
              v-if="detailVideoList.length>0"
              v-for="item in detailVideoList"
              style="width: 220px;height: 130px; object-fit:fill"
              :src="item"
              controls
              autoplay
              @click="showVideo(item)"
            >
              您的浏览器不支持
              video 标签。
            </video>
            <img
              v-if="detailVideoList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noVid.png"
              alt
            />
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 巡检详情dia结束 -->
    <!-- 整改记录列表开始 -->
    <!-- :title="`整改记录(${userName})`" -->

    <el-dialog
      :title="`整改记录`"
      class="riskDia"
      :visible.sync="dialogHistory"
      width="80%"
      :before-close="historyDiaClose"
    >
      <el-row class="tableRow">
        <el-table border :data="tableDataZheng" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80">
            <template slot-scope="scope">
              <span :title="scope.row.id" class="overflowDoit">{{scope.row.id}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="assign_username" label="指派人">
            <template slot-scope="scope">
              <span
                :title="scope.row.assign_username"
                class="overflowDoit"
              >{{scope.row.assign_username}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="rectify_username" label="整改人">
            <template slot-scope="scope">
              <span
                :title="scope.row.rectify_username"
                class="overflowDoit"
              >{{scope.row.rectify_username}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="review_username" label="复查人">
            <template slot-scope="scope">
              <span
                :title="scope.row.review_username"
                class="overflowDoit"
              >{{scope.row.review_username}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status_show" label="整改状态">
            <template slot-scope="scope">
              <span :title="scope.row.status_show" class="overflowDoit">{{scope.row.status_show}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="rectify_overtime" label="整改规定时间">
            <template slot-scope="scope">
              <span
                :title="scope.row.rectify_overtime"
                class="overflowDoit"
              >{{scope.row.rectify_overtime}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button @click="checkOutDetails(scope.row.id)" class="tableBtnRisk">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableDataZheng.length>0"
          @size-change="handleSizeChangeZheng"
          @current-change="handleCurrentChangeZheng"
          :current-page="currentPageZheng"
          :page-sizes="[3, 5, 10,15, 20]"
          :page-size="pageSizeZheng"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCountZheng"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 整改记录dia结束 -->

    <!-- 巡检记录巡检详情开始(整改记录点击查看详情) -->
    <!-- :title="`巡检记录巡检详情(${userName})`" -->
    <el-dialog
      :title="`整改记录详情`"
      class="riskDia checkDetails"
      :visible.sync="dialogdh"
      width="50%"
      :before-close="dialogdhClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formDetailsZheng" label-width="160px">
          <el-form-item label="风险点名称">
            <el-input style="width:70%" disabled v-model="formDetailsZheng.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="班次名称">
            <el-input style="width:70%" disabled v-model="formDetailsZheng.safety_inspection_name"></el-input>
          </el-form-item>
          <el-form-item label="巡检人">
            <el-input style="width:70%" disabled v-model="formDetailsZheng.user_name"></el-input>
          </el-form-item>
          <el-form-item label="巡检状态">
            <el-input
              style="width:70%"
              :class="formDetailsZheng.status_show=='正常'?'isBlue':'isRed'"
              disabled
              v-model="formDetailsZheng.status_show"
            ></el-input>
          </el-form-item>
          <el-form-item label="巡检备注" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              style="width:88%"
              disabled
              v-model="formDetailsZheng.remark"
            ></el-input>
          </el-form-item>
          <el-form-item label="巡检图片或视频" prop="desc" style="padding-top:10px;">
            <img
              v-if="changeImgCheckList"
              v-for="item in changeImgCheckList"
              @click="showPic(item)"
              style="width: 220px;height: 130px; margin-right:10px;"
              :src="item"
              alt
            />
            <img
              v-if="changeImgCheckList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noPic.png"
              alt
            />
            <!-- controls="controls" -->
            <video
              v-if="changeVideoCheckList"
              v-for="item in changeVideoCheckList"
              controls
              autoplay
              style="width: 220px;height: 130px; object-fit:fill"
              :src="item"
              @click="showVideo(item)"
            >
              您的浏览器不支持
              video 标签。
            </video>
            <img
              v-if="changeVideoCheckList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noVid.png"
              alt
            />
          </el-form-item>
          <el-form-item label="巡检时间">
            <el-input style="width:70%" disabled v-model="formDetailsZheng.add_time"></el-input>
          </el-form-item>
          <el-form-item label="整改指派人">
            <el-input style="width:70%" disabled v-model="formDetailsZheng.assign_username"></el-input>
          </el-form-item>
          <el-form-item label="指派完成时间">
            <el-input style="width:70%" disabled v-model="formDetailsZheng.rectify_overtime"></el-input>
          </el-form-item>
          <el-form-item label="整改人">
            <el-input style="width:70%" disabled v-model="formDetailsZheng.rectify_username"></el-input>
          </el-form-item>
          <el-form-item label="整改时间">
            <el-input style="width:70%" disabled v-model="formDetailsZheng.rectify_time"></el-input>
          </el-form-item>
          <el-form-item label="整改备注" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              style="width:88%"
              disabled
              v-model="formDetailsZheng.rectify_remarks"
            ></el-input>
          </el-form-item>
          <el-form-item label="整改图片或视频" prop="desc" style="padding-top:10px;">
            <img
              v-if="changeImgList.length>0"
              v-for="item in changeImgList"
              @click="showPic(item)"
              style="width: 220px;height: 130px; margin-right:10px;"
              :src="item"
              alt
            />
            <img
              v-if="changeImgList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noPic.png"
              alt
            />
            <!-- controls="controls" -->
            <video
              v-if="changeVideoResetList.length>0"
              v-for="item in changeVideoResetList"
              style="width: 220px;height: 130px; object-fit:fill"
              :src="item"
              controls
              autoplay
              @click="showVideo(item)"
            >
              您的浏览器不支持
              video 标签。
            </video>
            <img
              v-if="changeVideoCheckList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noVid.png"
              alt
            />
          </el-form-item>
          <el-form-item label="复查人">
            <el-input style="width:70%" disabled v-model="formDetailsZheng.review_username"></el-input>
          </el-form-item>
          <el-form-item label="复查时间">
            <el-input style="width:70%" disabled v-model="formDetailsZheng.review_time"></el-input>
          </el-form-item>
          <el-form-item label="复查备注" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              style="width:88%"
              disabled
              v-model="formDetailsZheng.review_remarks"
            ></el-input>
          </el-form-item>
          <el-form-item label="复查图片或视频" prop="desc" style="padding-top:10px;">
            <img
              v-if="changeImgResetList.length>0"
              v-for="item in changeImgResetList"
              @click="showPic(item)"
              style="width: 220px;height: 130px; margin-right:10px;"
              :src="item"
              alt
            />
            <img
              v-if="changeImgResetList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noPic.png"
              alt
            />
            <!-- controls="controls" -->
            <video
              v-if="changeVideoResetList.length>0"
              v-for="item in changeVideoResetList"
              style="width: 220px;height: 130px; object-fit:fill"
              :src="item"
              controls
              autoplay
              @click="showVideo(item)"
            >
              您的浏览器不支持
              video 标签。
            </video>
            <img
              v-if="changeVideoCheckList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noVid.png"
              alt
            />
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 巡检记录巡检详情结束 -->
    <!-- 考试记录 -->
    <el-dialog
      :title="`考试记录`"
      class="riskDia"
      :visible.sync="testDia"
      width="80%"
      :before-close="testClose"
      @close="testClosed"
    >
      <el-row class="tableRowModify">
        <el-table border :data="testList" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
          <el-table-column align="center" prop="exam_count" label="考试人">
            <template slot-scope="scope">
              <span
                :title="scope.row.exam_count"
                class="overflowDoit"
              >{{scope.row.nickname+'-'+scope.row.username}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="count" label="考试得分">
            <template slot-scope="scope">
              <span :title="scope.row.count" class="overflowDoit">{{scope.row.count}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="add_time" label="考试结束时间">
            <template slot-scope="scope">
              <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                style="margin:0 auto!important;"
                @click="testCheckOut(scope.row)"
                class="tableBtnRisk"
              >查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="testList.length>0"
          @size-change="handleSizeChangeTest"
          @current-change="handleCurrentChangeTest"
          :current-page="currentPageTest"
          :page-sizes="[3, 5, 10, 15,20]"
          :page-size="pageSizeTest"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCountTest"
        ></el-pagination>
      </el-row>
      <!-- <el-row class="footerBox">
        <el-button @click="addFormSubmit" class="checkOutBtn footerBtn">提交</el-button>
        <el-button @click="handleClearSubmit" class="checkOutBtn footerBtn">清空</el-button>
      </el-row>-->
    </el-dialog>
    <!-- 考试记录 -->

    <!-- 考试详情 -->
    <el-dialog
      :title="`考试详情`"
      class="riskDia modifyHeight"
      :visible.sync="testDetails"
      width="50%"
      :before-close="testDetailsClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="testData" label-width="100px">
          <el-form-item label="试卷详情">
            <div class="testDetailsBox">
              <div class="testItem" v-for="item,index in testData.content">
                <div class="testTitle">
                  {{(index+1)+'.'}}
                  <span v-if="item.questionType==0" style="color:blue">{{`(单选)`}}</span>
                  <span v-if="item.questionType==1" style="color:orange">{{`(多选)`}}</span>
                  <span v-if="item.questionType==2" style="color:skyblue">{{`(简答)`}}</span>
                  {{item.fldName}}
                </div>
                <div class="testAnswer" style="color:green">{{"正确答案:"+item.right}}</div>
                <div class="testAnswer" style="color:red">{{"考生答案:"+item.fldAnswer}}</div>
                <div
                  class="testOptions"
                  v-if="item.questionType!=2"
                  v-for="items in item.QuestionOptionList"
                >{{items.fldOptionIndex+'.'+items.fldOptionText}}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="考试结束时间">
            <el-input style="width:90%" disabled v-model="testData.add_time"></el-input>
          </el-form-item>
          <el-form-item label="考试得分">
            <el-input style="width:90%" disabled v-model="testData.count"></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 考试详情 -->

    <!-- 图片dia开始 -->
    <el-dialog
      class="riskDia imgDia"
      :visible.sync="imgDia"
      width="50%"
      :before-close="imgDiaClose"
    >
      <el-row class="tableRow">
        <img :src="showImgUrl" alt />
      </el-row>
    </el-dialog>
    <!-- 图片dia结束 -->
    <!-- 图片dia开始 -->
    <el-dialog
      class="riskDia videoDia"
      :visible.sync="videoDia"
      width="60%"
      :before-close="videoDiaClose"
    >
      <el-row class="tableRow">
        <video controls="controls" style=" object-fit:fill" :src="showVideoUrl" @click="showVideo">
          您的浏览器不支持
          video 标签。
        </video>
      </el-row>
    </el-dialog>
    <!-- 图片dia结束 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      platId: '',
      checkOutIsMine: '',
      editIsMine: '',
      valueClass: '',
      checkOutName: '',
      platList: [],
      isTrue: [
        { id: '0', label: '是' },
        { id: '1', label: '否' },
      ],
      isTrue1: [
        { id: '0', label: '是' },
        { id: '1', label: '否' },
        { id: '2', label: '不涉及' },
      ],
      classList: [],
      editClassList: [],
      userName: '',
      valueClass: '',
      valueClassEdit: '',
      token: '',
      // 操作
      dialogVisible: false,
      // 风险
      riskDialogVisible: false,
      // 查看风险
      riskDialogDetail: false,
      // 设备设施清单
      equipmentDialogVisible: false,
      // 查看设备设施
      equipmentDialogDetail: false,
      // 设备设施风险
      equipmentRisk: false,
      // 查看设备设施风险
      equipmentDetail: false,
      // 作业活动清单
      workVisible: false,
      // 作业活动清单详情
      workDetail: false,
      // 作业活动风险
      workRisk: false,
      // 作业活动风险查看
      workRiskDetail: false,
      // 隐患排查
      dangerCheck: false,
      // 隐患排查详情
      dangerCheckDetail: false,
      // 企业四色图
      colorPic: false,
      // 企业信息
      infoVisibale: false,
      // 巡检记录
      historyV: false,
      // 考试记录
      testDia: false,
      // 考试详情
      testDetails: false,

      companyName: '',
      equipmentName: '',
      equipmentName2: '',
      workName: '',
      workRiskName: '',
      dangerName1: '',
      // 风险点名称
      riskName: '',
      checkOutId: '',
      formData: {},
      formData1: {},
      formData2: {},
      options: [
        {
          value: ' ',
          label: '全部',
        },
        {
          value: 1,
          label: '是',
        },
        {
          value: 2,
          label: '否',
        },
      ],
      value: '',
      startTime: '',
      currentPage: 1,
      totalCount: 0,
      tableData: [],
      tableDataNo: [],
      pageSize: 20,

      // 风险管控清单
      currentPage1: 1,
      totalCount1: 0,
      tableData1: [],
      pageSize1: 10,

      // 设备设施清单
      currentPage2: 1,
      totalCount2: 0,
      tableData2: [],
      pageSize2: 10,

      // 设备设施风险评估清单
      currentPage3: 1,
      totalCount3: 0,
      tableData3: [],
      pageSize3: 10,
      formData3: {},

      // 作业活动清单
      currentPage4: 1,
      totalCount4: 0,
      tableData4: [],
      pageSize4: 10,
      formData4: {},

      // 作业活动风险评价清单
      currentPage5: 1,
      totalCount5: 0,
      tableData5: [],
      pageSize5: 10,
      formData5: {},

      // 隐患排查清单
      currentPage6: 1,
      totalCount6: 0,
      tableData6: [],
      pageSize6: 10,
      formData6: {},

      // 巡检记录
      currentPage7: 1,
      totalCount7: 0,
      tableData7: [],
      pageSize7: 10,
      formData7: {},
      // 企业四色图
      fourColorPic: '',
      companyData: {},

      // 整改记录列表
      tableDataZheng: [],
      currentPageZheng: 1,
      pageSizeZheng: 10,
      totalCountZheng: 0,

      // 巡检详情
      formDataXun: {},
      dialogDetailXun: false,
      // 考试详情
      // 考试列表
      currentPageTest: 1,
      totalCountTest: 0,
      testList: [],
      pageSizeTest: 10,
      testData: {},

      // !巡检详情图片视频
      detailImgList: [],
      detailVideoList: [],
      // 整改记录--巡检图片或视频
      changeVideoCheckList: [],
      changeImgCheckList: [],
      // 整改记录--整改图片或视频
      changeVideoList: [],
      changeImgList: [],
      // 整改记录--复查图片或视频
      changeVideoResetList: [],
      changeImgResetList: [],
      showImgUrl: '',
      showVideoUrl: '',
      imgDia: false,
      videoDia: false,
      dialogHistory: false,
      dialogdh: false,
      formDetailsZheng: {},

      historyId1: '',

      editData: {},
      editCid: '',
      editDia: false,
      // 判断是否为第三方
      isMine: true,
      // 平台id
      otherId: '',
      loginType: '',
    }
  },
  methods: {
    // 点击编辑
    async handleEdit(row) {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', row.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/user/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.editData = res.datas
      this.editCid = row.id
      this.editIsMine = row.is_mine
      console.log('row.ismine', row)
      this.editDia = true
    },
    // 编辑提交
    async editFormSubmit() {
      if (this.editData.nickname.trim().length == 0)
        return this.$message.error('请填写企业名称')
      if (this.editData.type.trim().length == 0)
        return this.$message.error('请选择企业分类')
      console.log('企业分类', this.valueClassEdit)
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editCid)
      param.append('is_mine', this.editIsMine)
      param.append('nickname', this.editData.nickname)
      param.append('short_name', this.editData.short_name)
      param.append('charge_name', this.editData.charge_name)
      param.append('charge_mobile', this.editData.charge_mobile)
      param.append('safe_name', this.editData.safe_name)
      param.append('safe_mobile', this.editData.safe_mobile)
      param.append('address', this.editData.address)
      param.append('member_num', this.editData.member_num)
      param.append('setup_time', this.editData.setup_time)
      param.append('area', this.editData.area)
      param.append('credit_code', this.editData.credit_code)
      param.append('fixed_assets', this.editData.fixed_assets)
      param.append('floorage', this.editData.floorage)
      param.append('subsidiary', this.editData.subsidiary)
      param.append('organization_name', this.editData.organization_name)
      param.append('organization_mobile', this.editData.organization_mobile)
      param.append('duty_mobile', this.editData.duty_mobile)
      param.append('professionals_num', this.editData.professionals_num)
      param.append('parttime_num', this.editData.parttime_num)
      param.append('is_safe', this.editData.is_safe)
      param.append('is_record', this.editData.is_record)
      param.append('is_risk', this.editData.is_risk)
      param.append('content', this.editData.content)
      param.append('competent_department', this.editData.competent_department)
      param.append('type', this.editData.type)
      // param.append('type', this.valueClassEdit)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/user/edit',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('提交成功')
      this.editDia = false
      this.getCompanyList()
      this.getClassList()
    },
    async handleSearch() {
      this.currentPage = 1
      if (this.valueClass == '全部分类') {
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('company_name', this.companyName)
        param.append('type', '')
        // const { data: res } = await this.$http({
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/platform/Totaldata',
          data: param,
        })
        // console.log('huo', res)
        if (res.code != 200) return this.$message.error(res.datas.msg)
        // this.tableData = res.datas
        this.tableDataNo = res.datas
        console.log('截取前', this.tableData)
        this.tableData = this.tableDataNo.slice(
          (this.currentPage - 1) * this.pageSize,
          (this.currentPage - 1) * this.pageSize + this.pageSize
        )
        console.log('大数据截取后', this.tableData)
        this.totalCount = res.datas.length
      } else {
        this.getCompanyList()
      }
    },
    // 获取大数据列表
    async getCompanyList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('nickname', this.companyName)
      param.append('type', this.valueClass)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      param.append('platform_id', this.platId)
      // const { data: res } = await this.$http({
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/user/index',
        data: param,
      })
      // console.log('huo', res)
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
      // this.tableDataNo = res.datas
      // console.log('截取前', this.tableData)
      // this.tableData = this.tableDataNo.slice(
      //   (this.currentPage - 1) * this.pageSize,
      //   (this.currentPage - 1) * this.pageSize + this.pageSize
      // )
      console.log('大数据截取后', this.tableData)
      // this.totalCount = res.datas.length
    },
    // *查看隐患排查详情
    async handleDangerCheck(row) {
      this.dangerName1 = row.danger_name
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger_investigation/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData6 = res.datas
      console.log('隐患排查详情', this.formData6)

      this.dangerCheckDetail = true
    },
    // *查看作业风险详情
    async checkOutWorkRisk(row) {
      this.workRiskName = row.danger_name
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger_activity_evaluation/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData5 = res.datas
      console.log('作业风险详情', this.formData5)

      // this.workRisk = false
      this.workRiskDetail = true
    },
    // *查看作业详情
    async checkOutWork(row) {
      this.workName = row.danger_accident
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger_activity/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData4 = res.datas
      console.log('作业活动详情', this.formData4)

      // this.workVisible = false
      this.workDetail = true
    },
    // *查看设备设施风险详情
    async checkOutEquipmentRisk(row) {
      this.equipmentName2 = row.danger_name
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Danger_Facility_Evaluation/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData3 = res.datas

      // this.equipmentRisk = false
      this.equipmentDetail = true
    },
    // *查看设备设施
    async checkOutEquipment(row) {
      this.equipmentName = row.danger_name
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Danger_Facility/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData2 = res.datas
      console.log('设备设施详情', this.formData2)
      // this.equipmentDialogVisible = false
      this.equipmentDialogDetail = true
    },

    // *风险管控详情查看
    async checkOutRisk(row) {
      this.riskName = row.danger_name
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Danger/detail',
        data: param,
      })
      console.log(res)
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData1 = res.datas
      if (this.formData1.danger_level == 1) {
        this.formData1.danger_level = '低风险'
      }
      if (this.formData1.danger_level == 2) {
        this.formData1.danger_level = '一般风险'
      }
      if (this.formData1.danger_level == 3) {
        this.formData1.danger_level = '较大风险'
      }
      if (this.formData1.danger_level == 4) {
        this.formData1.danger_level = '重大风险'
      }
      console.log('风险管控清单详情数据', this.formData1)

      // this.riskDialogVisible = false
      this.riskDialogDetail = true
    },
    async testCheckOut(row) {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Exam/recordDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.testData = res.datas
      console.log('考试详情', this.testData)
      this.testDetails = true
    },
    // todo点击风险管控清单(获取数据)
    async getRiskList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage1)
      param.append('length', this.pageSize1)
      param.append('id', this.checkOutId)
      param.append('id', this.checkOutId)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData1 = res.datas.list
      console.log('风险管控清单', this.tableData1)
      this.totalCount1 = parseInt(res.datas.count)
    },
    // todo点击风险管控清单
    riskClick() {
      this.getRiskList()
      this.riskDialogVisible = true
      this.dialogVisible = false
    },
    // todo点击设备设施清单(获取数据)
    async getEquipmentList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage2)
      param.append('length', this.pageSize2)
      param.append('id', this.checkOutId)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger_facility/index',
        data: param,
      })
      console.log('设备设施清单', res)
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData2 = res.datas.list
      this.totalCount2 = parseInt(res.datas.count)
    },
    // todo点击设备设施清单
    equipmentClick() {
      this.getEquipmentList()
      this.equipmentDialogVisible = true
      this.dialogVisible = false
    },
    // todo点击设备设施风险评价(获取数据)
    async getEquipmentRiskList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage3)
      param.append('length', this.pageSize3)
      param.append('id', this.checkOutId)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Danger_Facility_Evaluation/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData3 = res.datas.list
      console.log('设备设施风险评价清单', this.tableData3)
      this.totalCount3 = parseInt(res.datas.count)
    },
    // todo点击设备设施风险评价
    equipmentRiskClick() {
      this.getEquipmentRiskList()
      this.equipmentRisk = true
      this.dialogVisible = false
    },
    // todo点击作业活动清单(获取数据)
    async getWorkList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage4)
      param.append('length', this.pageSize4)
      param.append('id', this.checkOutId)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger_activity/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData4 = res.datas.list
      console.log('作业活动清单', this.tableData4)
      this.totalCount4 = parseInt(res.datas.count)
    },
    // todo点击作业活动清单
    workClick() {
      this.getWorkList()
      this.workVisible = true
      this.dialogVisible = false
    },
    // todo点击作业活动风险评价(获取数据)
    async getWorkRiskList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage5)
      param.append('length', this.pageSize5)
      param.append('id', this.checkOutId)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger_activity_evaluation/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData5 = res.datas.list
      this.totalCount5 = parseInt(res.datas.count)
    },
    // todo点击作业活动风险评价
    workRiskClick() {
      this.getWorkRiskList()
      this.workRisk = true
      this.dialogVisible = false
    },
    // todo点击隐患排查清单(获取数据)
    // todo点击隐患排查清单
    async getDangerList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage6)
      param.append('length', this.pageSize6)
      param.append('id', this.checkOutId)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger_investigation/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData6 = res.datas.list
      this.totalCount6 = parseInt(res.datas.count)
    },
    dangerClick() {
      this.getDangerList()
      this.dangerCheck = true
      this.dialogVisible = false
    },
    // todo点击查看四色图(获取数据)
    async getColrUrl() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.checkOutId)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/user/pic',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.fourColorPic = res.datas.img1
    },
    // todo点击查看四色图
    colorClick() {
      this.getColrUrl()
      this.colorPic = true
      this.dialogVisible = false
    },
    // todo点击企业信息(获取数据)
    async getCompanyInfo() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.checkOutId)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/user/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.companyData = res.datas
      console.log('企业信息', this.companyData)
    },
    // todo点击企业信息
    infoClick() {
      this.getCompanyInfo()
      this.infoVisibale = true
      this.dialogVisible = false
    },
    // todo点击巡检记录(获取数据)
    async getHistoryList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('length', this.pageSize7)
      param.append('page', this.currentPage7)
      param.append('id', this.checkOutId)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/SafetyInspectionRecord/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData7 = res.datas.list
      this.totalCount7 = parseInt(res.datas.count)
      console.log('巡检记录', this.tableData7)
    },
    goDatacheck() {
      window.open(
        'http://anquan.sxpz.vip/AppApi/Company/index?id=' + this.checkOutId
      )
    },
    // 点击考试记录
    goTestcheck() {
      this.getTestList()
      this.testDia = true
      this.dialogVisible = false
    },
    // 获取考试记录
    async getTestList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage3)
      param.append('length', this.pageSize3)
      param.append('id', this.checkOutId)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Exam/recordList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.testList = res.datas.list
      console.log('考试记录列表', this.tableData3)
      this.totalCountTest = parseInt(res.datas.count)
    },
    // todo点击巡检记录
    historyClick() {
      this.getHistoryList()
      this.historyV = true
      this.dialogVisible = false
    },
    // ?点击巡检详情
    async checkOutXun(row) {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/SafetyInspectionRecord/detail',
        data: param,
      })
      console.log('巡检记录详情', res)
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formDataXun = res.datas
      this.detailImgList = res.datas.inspection_img
      this.detailVideoList = res.datas.inspection_video
      this.dialogDetailXun = true
    },
    // *点击整改记录(获取数据)
    async getHistoryList1() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('length', this.pageSizeZheng)
      param.append('page', this.currentPageZheng)
      param.append('id', this.historyId1)
      // param.append('company_name', this.companyName)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/SafetyInspectionRecord/record',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableDataZheng = res.datas.list
      console.log('整改记录', this.tableDataZheng)
      this.totalCountZheng = parseInt(res.datas.count)
    },
    // *点击整改记录
    async changeHistory(id) {
      this.historyId1 = id
      this.getHistoryList1()
      this.dialogHistory = true
    },
    // 点击查看详情(整改记录)
    async checkOutDetails(id) {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', id)
      param.append('is_mine', this.checkOutIsMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/SafetyInspectionRecord/record_detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formDetailsZheng = res.datas
      console.log('整改记录', this.formDetailsZheng)
      // 巡检图片视频
      this.changeImgCheckList = res.datas.inspection_img
      this.changeVideoCheckList = res.datas.inspection_video
      // 整改图片视频
      this.changeImgList = res.datas.rectify_img
      this.changeVideoList = res.datas.rectify_video
      console.log('整改图片', this.changeImgList)
      // 复查图片视频
      this.changeImgResetList = res.datas.review_img
      console.log('复查图片', this.changeImgResetList)
      this.changeVideoResetList = res.datas.review_video

      this.dialogHistory = false
      this.dialogdh = true
    },
    checkOut(row) {
      if (row.is_mine == 0) {
        this.isMine = false
        this.otherId = row.platform_id
      } else if (row.is_mine) {
        this.isMine = true
      }
      this.checkOutName = row.nickname
      this.checkOutIsMine = row.is_mine
      this.checkOutId = row.id

      this.dialogVisible = true
    },
    showPic(url) {
      this.showImgUrl = url
      this.imgDia = true
    },
    showVideo(url) {
      this.showVideoUrl = url
      this.videoDia = true
    },
    // 操作
    handleClose() {
      this.dialogVisible = false
    },
    handleClosed() {},
    // 风险
    riskHandleClose() {
      this.riskDialogVisible = false
      this.checkOutId = ''
    },
    // 风险详情
    riskDetailClose() {
      this.riskDialogDetail = false
    },
    equipmentHandleClose() {
      this.equipmentDialogVisible = false
    },
    equipmentDetailClose() {
      this.equipmentDialogDetail = false
    },
    equipmentRiskClose() {
      this.equipmentRisk = false
    },
    equipmentRiskDetailClose() {
      this.equipmentDetail = false
    },
    workClose() {
      this.workVisible = false
    },
    workDetailClose() {
      this.workDetail = false
    },
    workRiskClose() {
      this.workRisk = false
    },
    workRiskDetailClose() {
      this.workRiskDetail = false
    },
    dangerCheckClose() {
      this.dangerCheck = false
    },
    dangerCheckDetailClose() {
      this.dangerCheckDetail = false
    },
    colorPicClose() {
      this.colorPic = false
    },
    infoVisibaleClose() {
      this.infoVisibale = false
    },
    historyClose() {
      this.currentPage7 = 1
      this.historyV = false
    },
    historyDiaClose() {
      this.currentPageZheng = 1
      this.historyId1 = ''
      this.dialogHistory = false
    },
    dialogDetailXunClose() {
      this.dialogDetailXun = false
    },
    dialogdhClose() {
      this.dialogdh = false
    },
    editDiaClose() {
      this.editCid = ''
      this.editIsMine = ''
      this.editDia = false
    },
    editDiaClosed() {
      this.editCid = ''
      this.editIsMine = ''
    },
    imgDiaClose() {
      this.imgDia = false
    },
    videoDiaClose() {
      this.videoDia = false
    },
    testClose() {
      this.testDia = false
    },
    testClosed() {},
    testDetailsClose() {
      this.testDetails = false
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getCompanyList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getCompanyList()
    },
    // handleSizeChange(val) {
    //   this.pageSize = val
    //   this.tableData = this.tableDataNo.slice(
    //     (this.currentPage - 1) * this.pageSize,
    //     (this.currentPage - 1) * this.pageSize + this.pageSize
    //   )
    // },
    // handleCurrentChange(val) {
    //   this.currentPage = val
    //   this.tableData = this.tableDataNo.slice(
    //     (this.currentPage - 1) * this.pageSize,
    //     (this.currentPage - 1) * this.pageSize + this.pageSize
    //   )
    // },
    handleSizeChange1(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSize1 = val
      this.getRiskList()
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val
      this.getRiskList()
    },
    handleSizeChange2(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSize2 = val
      this.getEquipmentList()
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val
      this.getEquipmentList()
    },
    handleSizeChange3(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSize3 = val
      this.getEquipmentRiskList()
    },
    handleCurrentChange3(val) {
      this.currentPage3 = val
      this.getEquipmentRiskList()
    },
    handleSizeChange4(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSize4 = val
      this.getWorkList()
    },
    handleCurrentChange4(val) {
      this.currentPage4 = val
      this.getWorkList()
    },
    handleSizeChange5(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSize5 = val
      this.getWorkRiskList()
    },
    handleCurrentChange5(val) {
      this.currentPage5 = val
      this.getWorkRiskList()
    },
    handleSizeChange6(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSize6 = val
      this.getDangerList()
    },
    handleCurrentChange6(val) {
      this.currentPage6 = val
      this.getDangerList()
    },
    handleSizeChange7(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSize7 = val
      this.getHistoryList()
    },
    handleCurrentChange7(val) {
      this.currentPage7 = val
      this.getHistoryList()
    },
    handleSizeChangeZheng(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSizeZheng = val
      this.getHistoryList1()
    },
    handleCurrentChangeZheng(val) {
      this.currentPageZheng = val
      this.getHistoryList1()
    },
    handleSizeChangeTest() {
      this.currentPageTest = 1
      this.pageSizeTest = val
      this.getTestList()
    },
    handleCurrentChangeTest() {
      this.currentPageTest = val
      this.getTestList()
    },
    async getClassList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/user/classification',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.classList = [...res.datas]
      this.editClassList = [...res.datas]
      this.classList.unshift('全部分类')
      console.log(res)
    },
    async getPlatList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/user/platform',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.platList = res.datas
      console.log('平台列表', res)
    },
  },
  created: function () {
    if (this.$route.query.class) {
      this.valueClass = this.$route.query.class
    }
    if (this.$route.query.companyName) {
      this.companyName = this.$route.query.companyName
    }
    window.setTimeout(() => {
      this.token = localStorage.getItem('btlh_token') || ''
      this.userName = localStorage.getItem('btlh_nickname') || ''
      this.getCompanyList()
      this.getClassList()
    }, 1000)
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;
  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }
  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;
    .el-date-editor.el-input,
    .el-input {
      width: 70%;
    }
    .selectCol {
      margin-right: 10px;
    }
    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    // /deep/ .el-input__icon {
    //   display: none !important;
    // }
    /deep/ .el-icon-arrow-up {
      display: none !important;
    }
    /deep/ .el-icon-circle-close {
      position: absolute;
      top: 7px;
      right: -5px;
    }
    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }
  // 表格
  .tableRow {
    padding: 0 10px;
  }
  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;
    td {
      padding: 5px 0;
    }
    td,
    th.is-leaf {
      border-bottom: 1px solid #eee;
    }
    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }
  .el-table::before {
    display: none;
  }
  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}
.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}
/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }
  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}

/deep/ .el-dialog__body {
  // display: flex;
  // flex-wrap: wrap;
  // padding-left: 60px;
  .el-button:nth-child(1) {
    margin: 0 auto 10px;
  }
  .el-button {
    display: block;
    margin: 0 auto;
    width: 50%;
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
  .el-button:last-child {
    // margin-bottom: 40px;
  }
}

.tableRow {
  padding: 0 10px;
  width: 100%;
}
/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  border: 1px solid #eee;
  td {
    padding: 5px 0;
  }
  td,
  th.is-leaf {
    border-bottom: 1px solid #eee;
  }
  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }
  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}
.el-table::before {
  display: none;
}
// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px 30px;
  }
  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}
.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-form-item__label {
    color: #000;
  }
  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }
  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}
/deep/ .el-input.is-disabled .el-input__inner {
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}
.modifyBtn:nth-child(1) {
  background-color: #447ed9 !important;
}
.modifyBtn {
  width: 80% !important;
  display: block;
  margin-left: 10px !important;
  background-color: #ec2324 !important;
}
.imgDia {
  /deep/.el-dialog {
    margin-top: 10vh !important;
  }
  /deep/ .el-dialog__header {
    background: space;
  }
  /deep/.el-dialog__body {
    height: 75vh;
    overflow: auto;
    padding-bottom: 10px;
    text-align: center;
    img {
      width: 80%;
    }
  }
}
.videoDia {
  /deep/.el-dialog {
    margin-top: 10vh !important;
  }
  /deep/ .el-dialog__header {
    background: space;
  }
  /deep/.el-dialog__body {
    // height: 75vh;
    overflow: auto;
    padding-bottom: 50px;
    text-align: center;
    video {
      width: 80%;
    }
  }
}
.modifyHeight /deep/ .el-dialog__body {
  height: 50vh;
  overflow: auto;
  padding-bottom: 50px;
  // text-align: center;
}

// 考试详情
.testDetailsBox {
  width: 90%;
  border: 1px solid #e2e2e2;
  padding: 15px;
  .testAnswer {
    padding-left: 20px;
    padding-right: 20px;
  }
  .testOptions {
    padding-left: 20px;
    padding-right: 20px;
  }
}
// 考试详情
// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}

// 修改详情和编辑字体大小
/deep/ .el-form-item__label {
  font-size: 13px !important;
  color: #666 !important;
}
// 修改详情和编辑字体大小
/deep/ .el-input__inner,
/deep/.el-textarea__inner {
  font-size: 12px !important;
  color: #999 !important;
}
</style>