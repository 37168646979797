<template>
  <div class="body">
    <div>
      <h1 class="title">{{content.title}}</h1>
      <p class="data">发布日期：{{content.addtime}}</p>
      <div class="line"></div>
      <div style="width:80%;margin:0 auto;" v-html="content.content"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: '',
      type: '',
    }
  },
  methods: {
    async getDetails() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.type)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/newsDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.content = res.datas
      console.log(this.content)
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    if (this.$route.query.id) {
      this.type = this.$route.query.id
      this.getDetails()
    }
  },
}
</script>
<style lang="less" scoped>
.body {
  padding-bottom: 50px;
}
.title {
  width: 60%;
  margin: 20px auto 0;
  text-align: center;
}
.data {
  text-align: center;
}
.line {
  margin: 0 auto;
  width: 90%;
  border-bottom: 1px dashed #333;
  margin-bottom: 20px;
}
.img1 {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.info {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
  font-size: 21px;
  line-height: 150%;
}
.detailcontainer {
  width: 80%;
  margin: 0 auto;
}
</style>